<template>
  <div>
    <div
      v-if="loading !== 'error'"
    >
      <div
        v-if="loading"
        class="d-flex align-items-center justify-content-center"
        style="height: 80vh"
      >
        <b-spinner variant="primary" />
      </div>
      <div v-else>
        <back-navigation
          :title="editMode ? 'Edit Sales Order' : 'Sales Order Baru'"
          :prevent-back="salesData.customer !== '' || salesData.items.length > 0"
          @preventBack="$bvModal.show('preventBack')"
        />
        <b-modal
          id="preventBack"
          title="Ingin keluar?"
          centered
        >
          Yakin ingin keluar? Progress pengisian form anda belum disimpan.
          <template
            #modal-footer
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="$bvModal.hide('preventBack'); $router.go(-1)"
            >
              Ya, keluar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="$bvModal.hide('preventBack')"
            >
              Tetap Disini
            </b-button>
          </template>
        </b-modal>
        <validation-observer ref="validateSales">
          <b-form
            @submit.prevent
          >
            <b-row>
              <b-col
                cols="12"
                lg="8"
              >
                <XyzTransitionGroup
                  appear
                  class="item-group"
                  xyz="fade stagger-1 left-3 delay-2"
                >
                  <b-card :key="1">
                    <b-row>
                      <b-col
                        cols="12"
                        lg="6"
                      >
                        <b-form-group
                          label="Tanggal"
                        >
                          <flat-pickr
                            v-model="salesData.date"
                            class="form-control"
                            placeholder="Pilih tanggal"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        lg="6"
                      >
                        <b-form-group
                          label="Customer"
                        >
                          <customer-auto-suggest
                            v-model="salesData.customer"
                            @onSelected="handleCustomerSelected"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                  <b-card
                    v-if="salesData.customer"
                    :key="2"
                  >
                    <item-auto-suggest
                      :key="salesData.items.length"
                      class="w-100 mb-1"
                      @onSelected="handleSelectedItem"
                    />
                    <b-overlay :show="loadingItem">
                      <vue-good-table
                        :columns="tableColumn"
                        :rows="salesData.items"
                        :sort-options="{ enabled: false }"
                        :pagination-options="{ enabled: false }"
                        style-class="vgt-table"
                      >
                        <div slot="emptystate">
                          <small class="text-center d-block">Tidak ada item</small>
                        </div>
                        <template
                          slot="table-row"
                          slot-scope="props"
                        >
                          <span v-if="props.column.field === 'name'">
                            <h6 class="m-0">
                              {{ props.row.name }}
                            </h6>
                            <small>
                              {{ props.row.no }}
                            </small>
                          </span>
                          <span v-else-if="props.column.field === 'volume'">
                            <validation-provider
                              #default="{ errors }"
                              :name="`Volume ${props.row.originalIndex}`"
                              rules="min_value:0|max_value:9999999|required"
                            >
                              <b-input-group
                                append="L"
                                class="input-group-merge"
                              >
                                <b-form-input
                                  v-model="salesData.items[props.row.originalIndex].volume"
                                  type="number"
                                  placeholder="100"
                                  min="0"
                                  @input="getSubtotal(props.row.originalIndex)"
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </span>
                          <span v-else-if="props.column.field === 'harga'">
                            <validation-provider
                              #default="{ errors }"
                              :name="`Harga ${props.row.originalIndex}`"
                              rules="min_value:0|max_value:9999999|required"
                            >
                              <b-input-group
                                prepend="Rp."
                                class="input-group-merge"
                              >
                                <b-form-input
                                  v-model="salesData.items[props.row.originalIndex].harga"
                                  type="number"
                                  placeholder="100"
                                  min="0"
                                  @input="getSubtotal(props.row.originalIndex)"
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </span>
                          <span v-else-if="props.column.field === 'diskon'">
                            <validation-provider
                              #default="{ errors }"
                              :name="`Diskon ${props.row.originalIndex}`"
                              :rules="salesData.items[props.row.originalIndex].discountMode === '%'
                                ? 'positive|min_value:0|max_value:100'
                                : `positive|min_value:0|max_value:${salesData.items[props.row.originalIndex].harga * salesData.items[props.row.originalIndex].volume}`"
                            >
                              <b-input-group>
                                <b-input-group-prepend>
                                  <b-dropdown
                                    :text="salesData.items[props.row.originalIndex].discountMode"
                                    variant="outline-secondary"
                                    toggle-class="px-1"
                                  >
                                    <b-dropdown-item @click="changeDiscountMode(props.row.originalIndex, '%'); getSubtotal(props.row.originalIndex)">
                                      %
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="changeDiscountMode(props.row.originalIndex, 'Rp'); getSubtotal(props.row.originalIndex)">
                                      Rp
                                    </b-dropdown-item>
                                  </b-dropdown>
                                </b-input-group-prepend>
                                <b-form-input
                                  v-if="salesData.items[props.row.originalIndex].discountMode === '%'"
                                  v-model="salesData.items[props.row.originalIndex].diskon"
                                  type="number"
                                  placeholder="100"
                                  min="0"
                                  max="100"
                                  @input="getSubtotal(props.row.originalIndex)"
                                />
                                <b-form-input
                                  v-else
                                  v-model="salesData.items[props.row.originalIndex].diskon"
                                  type="number"
                                  placeholder="1000000"
                                  min="0"
                                  @input="getSubtotal(props.row.originalIndex)"
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </span>
                          <span v-else-if="props.column.field === 'subtotal'">
                            <h6 class="m-0">
                              {{ formatCurrency(props.row.subtotal) }}
                            </h6>
                          </span>
                          <span v-else-if="props.column.field === 'action'">
                            <feather-icon
                              icon="Trash2Icon"
                              class="text-danger cursor-pointer mr-2"
                              size="20"
                              @click="deleteItem(props.row)"
                            />
                          </span>
                        </template>
                      </vue-good-table>
                    </b-overlay>
                  </b-card>
                  <b-card
                    v-if="salesData.items.length"
                    :key="3"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group label="Tanggal Pengiriman">
                          <b-input-group class="input-group-merge">
                            <flat-pickr
                              v-model="salesData.shipDate"
                              class="form-control"
                              placeholder="Pilih tanggal"
                            />
                            <b-input-group-append>
                              <b-button
                                variant="light"
                                @click="salesData.shipDate = ''"
                              >
                                <feather-icon
                                  icon="XIcon"
                                  class="text-secondary"
                                  size="16"
                                />
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group label="Syarat pembayaran">
                          <validation-provider
                            #default="{ errors }"
                            name="Syarat pembayaran"
                            rules="required"
                          >
                            <div
                              v-if="!paymentTermOptions.length"
                              class="d-flex"
                            >
                              <b-skeleton
                                width="100%"
                                height="40px"
                              />
                            </div>
                            <v-select
                              v-else
                              v-model="salesData.paymentTermName"
                              class="w-100 mb-1"
                              :options="paymentTermOptions"
                              label="name"
                              placeholder="Syarat pembayaran"
                              :clearable="false"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group label="Alamat Pengiriman">
                          <b-form-textarea
                            v-model="salesData.toAddress"
                            placeholder="Alamat Pengiriman"
                            rows="2"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group label="Keterangan">
                          <b-form-textarea
                            v-model="salesData.notes"
                            placeholder="Keterangan"
                            rows="2"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group label="PO Number">
                          <b-form-input
                            v-model="salesData.poNumber"
                            placeholder="PO Number"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group label="Nama pengiriman">
                          <validation-provider
                            #default="{ errors }"
                            name="Nama pengiriman"
                            rules="required"
                          >
                            <div
                              v-if="!shipmentOptions.length"
                              class="d-flex"
                            >
                              <b-skeleton
                                width="100%"
                                height="40px"
                              />
                            </div>
                            <v-select
                              v-else
                              v-model="salesData.shipmentName"
                              class="w-100 mb-1"
                              :options="shipmentOptions"
                              label="name"
                              placeholder="Nama pengiriman"
                              :clearable="false"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                  <b-card
                    v-if="salesData.items.length"
                    :key="4"
                  >
                    <b-row
                      v-if="prevData.attachment && prevData.attachment.length"
                    >
                      <b-col
                        v-for="(file, index) in prevData.attachment"
                        :key="index"
                        cols="12"
                        md="6"
                      >
                        <div class="custom-attachment d-flex align-items-center p-1 mb-1">
                          <div>
                            <small
                              class="font-weight-bold d-block text-primary cursor-pointer"
                              @click="handleAttachmentClick(file)"
                            >
                              {{ file.name.length > 32 ? `${file.name.substring(0, 32)}...` : file.name }}
                            </small>
                            <small class="text-muted">
                              {{ file.filesizeInMega }}
                            </small>
                          </div>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            class="ml-auto"
                            size="sm"
                            variant="outline-danger"
                            @click="deleteAttachment(file)"
                          >
                            <feather-icon
                              icon="TrashIcon"
                              size="20"
                            />
                          </b-button>
                        </div>
                      </b-col>
                    </b-row>
                    <vue-dropzone
                      id="dropzone"
                      ref="dropDataSheet"
                      :options="dropzoneOptions"
                      :use-custom-slot="true"
                      @vdropzone-error="dokumenError"
                    >
                      <div class="d-flex align-items-center justify-content-center h-100 pt-50">
                        <h6 class="text-muted font-weight-normal mt-50">
                          Upload Attachment
                        </h6>
                      </div>
                    </vue-dropzone>
                  </b-card>
                </XyzTransitionGroup>
              </b-col>
              <b-col
                cols="12"
                lg="4"
              >
                <XyzTransition
                  appear
                  xyz="fade right-3 delay-2"
                >
                  <sticky-panel v-if="salesData.customer && salesData.items.length">
                    <b-card>
                      <h4 class="mb-1">
                        Summary
                      </h4>
                      <router-link :to="{ name: 'customer-detail', params: {id: salesData.customer.id.toString()} }">
                        <h6 class="font-weight-bolder m-0">
                          {{ salesData.customer.name }}
                        </h6>
                      </router-link>
                      <small
                        v-if="salesData.customer.category || salesData.customer.priceCategory"
                        class="text-secondary"
                      >
                        {{ salesData.customer.category }} - {{ salesData.customer.priceCategory }}
                      </small>
                      <hr>
                      <div class="d-flex justify-content-between align-items-center mt-1">
                        <small class="text-muted">Subtotal</small>
                        <h6>{{ formatCurrency(salesData.subtotal) }}</h6>
                      </div>
                      <b-collapse
                        :visible="salesData.subtotal > 0"
                      >
                        <div class="mt-1 mb-1">
                          <small class="text-muted mt-1">Diskon</small>
                          <validation-provider
                            #default="{ errors }"
                            name="Diskon"
                            :rules="selectedDiscount === '%'
                              ? 'positive|min_value:0|max_value:100'
                              : `positive|min_value:0|max_value:${salesData.subtotal}`"
                          >
                            <b-input-group class="mt-50">
                              <b-input-group-prepend>
                                <b-dropdown
                                  :text="selectedDiscount"
                                  variant="outline-secondary"
                                >
                                  <b-dropdown-item @click="changeDiscount('%')">
                                    %
                                  </b-dropdown-item>
                                  <b-dropdown-item @click="changeDiscount('Rp')">
                                    Rp
                                  </b-dropdown-item>
                                </b-dropdown>
                              </b-input-group-prepend>
                              <b-form-input
                                v-if="selectedDiscount === '%'"
                                v-model="discount"
                                type="number"
                                :placeholder="selectedDiscount === '%' ? '100' : '1000000'"
                                min="0"
                                max="100"
                                @input="getTotal"
                              />
                              <b-form-input
                                v-else
                                v-model="discount"
                                type="number"
                                :placeholder="selectedDiscount === '%' ? '100' : '1000000'"
                                min="0"
                                @input="getTotal"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                        <div class="d-flex justify-content-between mt-1 align-items-center">
                          <small class="font-weight-bolder">Diskon</small>
                          <h5 class="font-weight-bolder">
                            {{ formatCurrency(salesData.diskon) }}
                          </h5>
                        </div>
                        <div
                          class="d-flex justify-content-between mt-1 align-items-center"
                        >
                          <small class="font-weight-bolder">Pajak</small>
                          <h6 class="font-weight-normal m-0">
                            {{ formatCurrency(11 / 100 * salesData.total) }}
                          </h6>
                        </div>
                        <div class="d-flex justify-content-between mt-1 align-items-center">
                          <small class="font-weight-bolder">Total</small>
                          <h5 class="font-weight-bolder text-primary">
                            {{ formatCurrency(salesData.total + (11 / 100 * salesData.total)) }}
                          </h5>
                        </div>
                      </b-collapse>
                      <b-overlay
                        v-b-popover.hover.top="customerNotInAccurate ? 'Customer yang dipilih belum ditambah ke Accurate, hubungi manager / sales admin untuk menambah customer.' : ''"
                        :show="loadingAdd"
                      >
                        <b-button
                          v-if="editMode && prevData.status === 'DRAFT'"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          block
                          variant="primary"
                          class="mt-1"
                          :disabled="customerNotInAccurate"
                          @click="loadingAdd ? null : salesData.customer.id.startsWith('C') ? addCustomerToAccurate({ draft: false }) : submitSalesOrder({ draft: false })"
                        >
                          <span v-if="!loadingAdd">Submit</span>
                          <b-spinner
                            v-else
                            small
                          />
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          block
                          :variant="editMode && prevData !== '' && prevData.status === 'DRAFT' ? 'outline-primary' : 'primary'"
                          class="mt-1"
                          :disabled="customerNotInAccurate"
                          @click="loadingAdd ? null : salesData.customer.id.startsWith('C')
                            ? addCustomerToAccurate({ draft: editMode && prevData !== '' && prevData.status === 'DRAFT' })
                            : submitSalesOrder({ draft: editMode && prevData !== '' && prevData.status === 'DRAFT' })"
                        >
                          <span v-if="!loadingAdd">{{ editMode ? 'Update' : 'Submit' }}</span>
                          <b-spinner
                            v-else
                            small
                          />
                        </b-button>
                        <b-button
                          v-if="!editMode"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          block
                          variant="outline-primary"
                          class="mt-1"
                          :disabled="customerNotInAccurate"
                          @click="loadingAdd ? null : salesData.customer.id.startsWith('C') ? addCustomerToAccurate({ draft: true }) : submitSalesOrder({ draft: true })"
                        >
                          <span v-if="!loadingAdd">Draft</span>
                          <b-spinner
                            v-else
                            small
                          />
                        </b-button>
                      </b-overlay>
                    </b-card>
                  </sticky-panel>
                </XyzTransition>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </div>
    <error-endpoint
      v-else
      :key="error"
      ref="error"
      :error="error"
    />
  </div>
</template>

<script>
import BackNavigation from '@/components/misc/BackNavigation.vue'
import StickyPanel from '@/components/misc/StickyPanel.vue'
import {
  BCard, BRow, BCol, BFormGroup, BForm, BOverlay, BFormInput, BInputGroup,
  BInputGroupPrepend, BInputGroupAppend, BDropdown, BDropdownItem, BFormTextarea, BSkeleton,
  BButton, BCollapse, BSpinner, VBPopover, BModal,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import CustomerAutoSuggest from '@/components/input/CustomerAutoSuggest.vue'
import ItemAutoSuggest from '@/components/input/ItemAutoSuggest.vue'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max, positive, integer, minValue, maxValue,
} from '@validations'
import vSelect from 'vue-select'
import VueDropzone from 'vue2-dropzone'
import { mapGetters } from 'vuex'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formatCurrency } from '@core/utils/filter'
import ErrorEndpoint from '@/components/misc/ErrorEndpoint.vue'
import moment from 'moment'

export default {
  components: {
    BForm,
    BCard,
    BackNavigation,
    StickyPanel,
    BRow,
    BCol,
    BFormGroup,
    flatPickr,
    CustomerAutoSuggest,
    ItemAutoSuggest,
    VueGoodTable,
    BOverlay,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BFormTextarea,
    BSkeleton,
    BButton,
    BCollapse,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    VueDropzone,
    ErrorEndpoint,
    BModal,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    id: {
      type: [Number, String],
      default: '',
    },
    selectedCustomer: {
      type: Object,
      default: () => {},
    },
    submit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      min,
      max,
      positive,
      integer,
      minValue,
      maxValue,
      selectedDiscount: '%',
      discount: 0,
      salesData: {
        date: new Date(),
        customer: '',
        items: [],
        shipDate: '',
        paymentTermName: '',
        poNumber: '',
        toAddress: '',
        notes: '',
        subtotal: 0,
        total: 0,
        diskon: 0,
        shipmentName: '',
      },
      prevData: '',
      error: '',
      loading: false,
      loadingItem: false,
      loadingAdd: false,
      paymentTermOptions: [],
      tableColumn: [
        {
          label: 'Item',
          field: 'name',
          sortable: false,
          width: '180px',
        },
        {
          label: 'Volume',
          field: 'volume',
          sortable: false,
          width: '120px',
        },
        {
          label: 'Harga',
          field: 'harga',
          sortable: false,
          width: '160px',
        },
        {
          label: 'Diskon',
          field: 'diskon',
          sortable: false,
          width: '200px',
        },
        {
          label: 'Subtotal',
          field: 'subtotal',
          sortable: false,
          width: '160px',
        },
        {
          label: '',
          field: 'action',
          tdClass: 'text-right',
          sortable: false,
        },
      ],
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: true,
        autoProcessQueue: false,
        maxFilesize: 5,
        dictDefaultMessage: 'Taruh File Disini',
        uploadMultiple: false,
        maxFiles: 10,
        dictCancelUpload: 'Batalkan',
        dictCancelUploadConfirmation: 'Apakah anda yakin?',
        dictRemoveFile: 'Hapus File',
        dictFileTooBig: 'File terlalu besar ({{filesize}}MB). Maximal ukuran: {{maxFilesize}}MB.',
        dictInvalidFileType: 'Maaf, anda tidak bisa mengupload jenis file ini, hanya bisa .pdf',
        dictMaxFilesExceeded: 'Tidak boleh mengupload lebih dari 1 file. Hapus file sebelumnya terlebih dahulu.',
      },
    }
  },
  computed: {
    editMode() {
      return this.id !== '' && this.$route.name === 'salesorder-edit'
    },
    customerNotInAccurate() {
      if (this.$root.role === 2 || this.$root.role === 3) return false
      if (this.salesData.customer && this.salesData.customer.id && this.salesData.customer.id.startsWith('C')) return true
      return false
    },
    ...mapGetters({
      shipmentOptions: 'getShipmentOptions',
    }),
  },
  async mounted() {
    if (this.editMode) {
      this.initForm()
    }

    if (this.selectedCustomer) {
      this.salesData.customer = this.selectedCustomer
    }

    this.$store.dispatch('getShipments')
    this.paymentTermOptions = await this.$store.dispatch('getPaymentTerm')
  },
  methods: {
    formatCurrency,
    initForm() {
      this.loading = true
      this.$store.dispatch('getSalesOrderDetail', +this.id).then(async result => {
        if (this.$root.role === 2 || (result.userCreate && result.userCreate.id === this.$store.getters.getCurrentUser.user.id)) {
          this.prevData = result
          this.salesData.date = new Date(moment(result.transDate, 'DD/MM/YYYY'))
          this.salesData.shipDate = new Date(moment(result.shipDate, 'DD/MM/YYYY'))
          this.salesData.paymentTermName = result.paymentTerm
          this.salesData.poNumber = result.poNumber
          this.salesData.toAddress = result.toAddress
          this.salesData.notes = result.description
          this.salesData.shipmentName = result.shipment

          await this.$store.dispatch('getCustomerDetail', result.customer.id).then(customer => {
            this.salesData.customer = {
              ...customer,
              category: customer.category.name,
              priceCategory: customer.priceCategory.name,
            }
          })

          this.salesData.items = result.items.map(item => ({
            id: item.id,
            name: item.name,
            no: item.no,
            volume: item.quantity,
            harga: item.unitPrice,
            diskon: item.itemCashDiscount,
            subtotal: item.totalPrice,
            discountMode: 'Rp',
            discount: item.itemCashDiscount,
          }))

          this.selectedDiscount = 'Rp'
          this.discount = result.cashDiscount
          this.salesData.subtotal = result.subTotal
          this.salesData.diskon = result.cashDiscount
          this.salesData.total = result.totalAmount - result.tax1Amount

          this.loading = false

          if (this.submit) {
            setTimeout(() => {
              this.submitSalesOrder({ draft: false })
            }, 500)
          }
        } else {
          this.loading = false
          this.$router.replace('/unauthorized')
        }
      }).catch(error => {
        this.loading = 'error'
        this.error = `${error.graphQLErrors[0].message}`
      })
    },
    handleCustomerSelected(data) {
      this.salesData.customer = data

      this.$store.dispatch('getCustomerDetail', data.id).then(result => {
        this.salesData.paymentTermName = result.term
        this.salesData.toAddress = result.billStreet
        this.discount = result.defaultSalesDisc ? result.defaultSalesDisc : 0
      })
    },
    async handleSelectedItem(data) {
      this.loadingItem = true
      await this.$store.dispatch('getItemsDetailCustomer', {
        item_id: data.id,
        customer_id: this.salesData.customer.id,
      }).then(async itemDetail => {
        this.salesData.items.push({
          ...data,
          volume: 0,
          harga: itemDetail.detailSellingPrice[0]?.price ?? 0,
          diskon: 0,
          subtotal: 0,
          discountMode: '%',
          discount: 0,
        })
        this.loadingItem = false
      })
    },
    changeDiscountMode(index, discount) {
      this.salesData.items[index].discountMode = discount
      this.salesData.items[index].diskon = '0'
    },
    changeDiscount(discount) {
      this.selectedDiscount = discount
      this.discount = '0'
      this.salesData.diskon = 0
      this.getTotal()
    },
    getSubtotal(index) {
      let total = 0

      const {
        volume, harga, diskon, discountMode,
      } = this.salesData.items[index]

      total = parseFloat(harga) * parseFloat(volume)

      if (diskon && discountMode === '%') {
        const discount = ((parseFloat(diskon) / 100) * total)
        total -= discount
        this.salesData.items[index].discount = discount
      }

      if (diskon && discountMode === 'Rp') {
        total -= parseFloat(diskon)
        this.salesData.items[index].discount = parseFloat(diskon)
      }

      this.salesData.items[index].subtotal = total

      this.getTotal()
    },
    getTotal() {
      let total = 0

      this.salesData.items.forEach(item => {
        if (item.subtotal) {
          total += +item.subtotal
        }
      })

      this.salesData.subtotal = total

      if (this.discount) {
        if (this.selectedDiscount === '%') {
          const discount = ((total / 100) * Number(this.discount))
          this.salesData.total = total - discount
          this.salesData.diskon = discount
        } else {
          const discount = Number(this.discount)
          this.salesData.total = total - discount
          this.salesData.diskon = discount
        }
      } else {
        this.discount = '0'
        this.salesData.diskon = 0
        this.salesData.total = total
      }
    },
    deleteItem(item) {
      this.$swal({
        title: 'Hapus item?',
        text: 'Progres input akan hilang dan tidak dapat dikembalikan',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          if (this.salesData.items.length === 1) {
            this.salesData.items = []
          } else {
            this.salesData.items.splice(item.originalIndex, 1)
          }

          this.getTotal()
        }
      })
    },
    handleAttachmentClick(file) {
      window.open(file.path, '_blank')
    },
    deleteAttachment(file) {
      this.$swal({
        title: 'Hapus attachment ?',
        text: 'Attachment yang dihapus tidak dapat dikembalikan.',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('deleteSalesOrderAttachment', file.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil menghapus attachment!',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })
            this.prevData.attachment = this.prevData.attachment.filter(el => el.id !== file.id)
          })
        }
      })
    },
    dokumenError(file, message) {
      if (message !== 'Upload canceled.') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    },
    addCustomerToAccurate({ draft }) {
      this.$swal({
        title: 'Customer ini belum tersedia di Accurate',
        text: 'Konfirmasi jika anda ingin menambah customer ke Accurate',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('addCustomerAccurate', this.salesData.customer.id).then(data => {
            if (data.data.addCustomerAccurate.status.toLowerCase() === 'success') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil menambah customer ke Accurate!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.submitSalesOrder({ draft })
            }
          })
        }
      })
    },
    submitSalesOrder({ draft }) {
      this.$refs.validateSales.validate().then(success => {
        if (success) {
          this.loadingAdd = true

          if (!this.editMode) {
            this.$store.dispatch('addSalesOrder', {
              customerNo: this.salesData.customer.customerNo,
              description: this.salesData.notes,
              transDate: this.salesData.date ? new Date(this.salesData.date).toISOString().slice(0, 10) : null,
              cashDiscount: parseFloat(this.salesData.diskon),
              paymentTermName: this.salesData.paymentTermName.name,
              poNumber: this.salesData.poNumber,
              shipDate: this.salesData.shipDate ? new Date(this.salesData.shipDate).toISOString().slice(0, 10) : null,
              toAddress: this.salesData.toAddress,
              items: this.salesData.items.map(el => ({
                item_code: el.no,
                price: parseFloat(el.harga),
                liter: parseFloat(el.volume),
                itemCashDiscount: parseFloat(el.discount),
              })),
              is_draft: draft,
              shipmentName: this.salesData.shipmentName ? this.salesData.shipmentName.name : null,
            }).then(async result => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil menambah sales order!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })

              if (result.data.addSalesOrder.id && this.$refs.dropDataSheet.getAcceptedFiles().length) {
                await this.$store.dispatch('salesOrderAttachment', {
                  sales_order_id: result.data.addSalesOrder.id,
                  files: this.$refs.dropDataSheet.getAcceptedFiles(),
                })
              }

              this.loadingAdd = false
              this.$router.replace({ name: 'salesorder-detail', params: { id: result.data.addSalesOrder.id } })
            }).catch(err => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Gagal menambah sales order! ${err}`,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
              this.loadingAdd = false
            })
          } else {
            this.$store.dispatch('updateSalesOrder', {
              id: +this.id,
              customerNo: this.salesData.customer.customerNo,
              description: this.salesData.notes,
              transDate: this.salesData.date ? new Date(this.salesData.date).toISOString().slice(0, 10) : null,
              cashDiscount: parseFloat(this.salesData.diskon),
              paymentTermName: this.salesData.paymentTermName.name,
              poNumber: this.salesData.poNumber,
              shipDate: this.salesData.shipDate ? new Date(this.salesData.shipDate).toISOString().slice(0, 10) : null,
              toAddress: this.salesData.toAddress,
              items: this.salesData.items.map(el => ({
                item_code: el.no,
                price: parseFloat(el.harga),
                liter: parseFloat(el.volume),
                itemCashDiscount: parseFloat(el.discount),
              })),
              shipmentName: this.salesData.shipmentName ? this.salesData.shipmentName.name : null,
              is_draft: draft,
            }).then(async () => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil mengupdate sales order!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })

              if (this.$refs.dropDataSheet.getAcceptedFiles().length) {
                await this.$store.dispatch('salesOrderAttachment', {
                  sales_order_id: +this.id,
                  files: this.$refs.dropDataSheet.getAcceptedFiles(),
                })
              }

              this.loadingAdd = false
              this.$router.replace({ name: 'salesorder-detail', params: { id: this.id } })
            }).catch(err => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Gagal menambah sales order! ${err}`,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
              this.loadingAdd = false
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.dz-progress {
  display: none;
}
.dropzone {
  padding: 0 !important;

  .dark-layout & {
    background: #283046;
  }
}
.vue-dropzone {
  border-radius: 4px;
  border: 1px solid #d8d6de;

  .dark-layout & {
    border: 1px solid #404656;
  }
}
.custom-attachment {
  border-radius: 8px;
  border: 2px solid rgb(223, 223, 223);
}
</style>
